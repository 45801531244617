import React from 'react';
import Collapse from 'rc-collapse';
import { CollapsePanelProps } from 'rc-collapse/lib/interface';
import 'rc-collapse/assets/index.css';
import { RichTextBlock } from '@/components/RichTextBlock';
import PlusIcon from '../../../public/assets/icons/plus.svg';
import MinusIcon from '../../../public/assets/icons/minus.svg';
// Import { faqs } from './static';

export const Accordion = ({ faqs, className = '', style = null, minusIcon = null, plusIcon = null }) => (
    <div className={`faq-accordion ${className}`} style={style}>
        <div className='faq-accordion--wrapper'>
            <Collapse
                accordion={true}
                expandIcon={(props: CollapsePanelProps) => props.isActive ? (
                    minusIcon ?? <MinusIcon />
                ) : (
                    plusIcon ?? <PlusIcon />
                )}
            >
                {faqs.map((item, index) => (
                    <Collapse.Panel key={item?.title} header={item?.title} className='text-red-100 select-none balance' forceRender>
                        <RichTextBlock
                            richText={item.text}
                            style={{ background: 'transparent', padding: 0 }}
                        />
                    </Collapse.Panel>
                ))}
            </Collapse>
        </div>
        <style jsx>{`
            .faq-accordion {
                background: #f9f9f9;
                //padding: 60px 40px 110px;
                padding: 0;

                .faq-accordion--wrapper {
                    max-width: 1300px;
                    margin: 0 auto;
                }

                @media (max-width: 768px) {
                    //padding: 40px 28px 80px;
                }

                @media (max-width: 576px) {
                    //padding: 20px 20px 60px;
                }
            }
        `}</style>
    </div>
);
