import React from 'react';
import LargeButton from '@/components/Views/Buttons';
import { SmallHeading } from '@/components/Views/Heading';
import { NormalText, SmallerText } from '@/components/Views/Text';
import IpadMedley from '@/components/IpadMedley/IpadMedley';
import { useDispatchUi } from '@/context/ui';
import { ButtonGroupRecord } from '../../graphql/generated/datocms';
import { MUZOLOGY_SIGNUP_URL } from '@/lib/defaults';

interface Props {
    background?: string;
    smallHeading?: string;
    infoItems?: any;
    buttonGroups?: ButtonGroupRecord[];

    // TODO: migrate old buttons to buttonGroups
    buttonClass?: string;
    buttonText?: string | object;
    buttonLink?: string;
    buttonIcon?: any;

    buttons?: any;
    buttonGroupHeading?: string;
    buttonGroupLeftClassName?: string;
    buttonGroupRightClassName?: string;

    buttonsCenter?: boolean;
    children?: any;
    wideButtons?: boolean;
}

const InfoItem = ({ header, text, icon, index }) => {
    const translateClass = index === 0 ? '-translate-x-[9px]' : '-translate-x-[5px]';
    return (
        <div className='info-item-container relative select-none flex flex-row lg:flex-col items-start gap-2 items-center md:items-start'>
            <img className={`mb-1 border-[#6E60E6] w-10 ${translateClass}`} src={icon?.url} alt={icon.alt ?? icon.title ?? 'icon'} aria-hidden='true' />
            <div className='flex flex-col justify-center items-center'>
                <h3 className='my-0 text-lg md:text-xl font-semibold text-[#111827] text-left mb-1 '>{header}</h3>
                {/*
                <NormalText className='block sm:hidden leading-[1.6] mt-0'>{text}</NormalText>
                */}
                <SmallerText className='hidden md:block leading-[1.6] mt-0'>{text}</SmallerText>
            </div>
            <style jsx>
                {`
                    .info-item-container {

                    }
                `}
            </style>
        </div>
    );
};

export const classText = (text) => text.replaceAll(' ', '-').toLowerCase();

const ExploreMath = (
    {
        background = null,
        buttonClass = null,
        buttonText = null,
        buttonLink = null,
        buttonIcon = null,
        smallHeading = null,
        infoItems = null,
        buttonGroups = null,
        buttonGroupHeading = null,
        buttonGroupLeftClassName = null,
        buttonGroupRightClassName = null,
        buttons = null,
        buttonsCenter = false,
        children = null,
        wideButtons = false
    }: Props) => {
    const id = 'explore-math';

    const dispatch: any = useDispatchUi();

    const createButtons = (buttons) => {

        return buttons?.map((button, index) => {
            let link = button.pageLink?.path ?? button.pageLink?.slug ?? button.link;
            console.log("BUTTON LINK", link);
            if (link === 'subscription-pricing') {
                link = MUZOLOGY_SIGNUP_URL;
            }
            return (
                <LargeButton
                    id={id + '-' + classText(button.title) + '-button'}
                    key={index}
                    className={`${button.style ?? 'blue-button'} ${button.class}`}
                    href={link}
                    text={button.title}
                    rightArrow={button.icon === 'right-arrow'}
                    downArrow={button.icon === 'down-arrow'}
                    rightDelta={button.icon === 'right-delta'}
                    onClick={button.videoLink ? () => dispatch({ type: 'PLAY_VIDEO', payload: button.videoLink.videoUrl }) : null}
                />
            );
        });
    };

    const buttonGroupNodes = buttonGroups?.map((buttonGroup, index) => {
        return (
            <div key={index} className={'button-group ' + buttonGroup.className}>
                {createButtons(buttonGroup.buttons)}
            </div>
        );
    });

    // get the left and right button groups
    const buttonGroupRight = buttonGroupNodes?.length > 0 ? buttonGroupNodes[0] : null;
    const buttonGroupLeft = buttonGroupNodes?.length > 1 ? buttonGroupNodes[1] : null;
    return (
        <div id={id} className='relative default-py default-px w-full font-Inter bg-[#A2D2E8]' style={{ background }}>
            <div className='relative flex default-max-w mx-auto flex-col lg:flex-row gap-6 lg:gap-10 items-center bg-no-repeat'>
                {/*<div className='relative z-0 w-full bg-red-100 slg:w-[45%] flex-grow text-right mt-0 default-full-width mt-[-5%] lg:mr-[-20px] lg:ml-[-60px] xl:ml-[-80px] 2xl:ml-[-200px]'>*/}

                {/* left column */}
                <div className='relative z-10 w-[100%]'>

                    {/* featured image */}
                    <IpadMedley className='mb-10 lg:mr-10' />

                    {/* left button group */}
                    <div className='hidden lg:flex justify-center w-full mb-10 mt-10 sm:mb-0 lg:pr-10'>
                        {buttonText && <div className='text-center lg:text-left block relative'>
                            <LargeButton
                                id={'explore-math-' + classText(buttonText) + '-button'}
                                className={buttonClass ?? 'blue-button'}
                                href={MUZOLOGY_SIGNUP_URL}
                                text={buttonText}
                            >
                                {buttonIcon}
                            </LargeButton>
                        </div>}
                        {buttonGroupLeft}
                    </div>

                </div>

                {/* right column */}
                <div className='relative z-10 w-full -bg-green-100'>
                    {smallHeading
                        && <SmallHeading
                            smallHeading={smallHeading}
                            className='text-[#334155] text-[22px] pb-6 lg:pb-4 tracking-wider font-semibold text-[#334155] lg:text-left'
                        />}
                    {/* <h3 className='font-semibold tracking-wider text-lg text-[#222] text-center md:text-left'>A NEW WAY TO EXPLORE MATH</h3> */}
                    <div className='info-items-container'>
                        {infoItems?.map((item, index) => {
                            return (
                                <InfoItem
                                    key={index}
                                    index={index}
                                    header={item.header}
                                    text={item.text}
                                    icon={item.icon}
                                />
                            );
                        })}
                    </div>

                    {buttonText && <div className='relative flex lg:hidden text-center lg:text-left mt-2 top-[30px] sm:top-[30px] lg:top-0'>
                        <div className='lg:hidden lg:bg-red-100 w-full'>
                            <LargeButton
                                id={'explore-math-' + classText(buttonText) + '-button'}
                                className={buttonClass ?? 'blue-button'}
                                href={MUZOLOGY_SIGNUP_URL}
                                text={buttonText}
                            >
                                {buttonIcon}
                            </LargeButton>
                        </div>
                    </div>}

                    {!wideButtons && <div className='flex relative z-40 flex-col mx-auto w-full mt-4 sm:mt-5 lg:mt-5 '>
                        <div className='flex flex-col mx-auto w-auto'>
                            {buttonGroupHeading && <NormalText className='flex justify-center items-center text-center text-base font-bold text-[#4B5563] my-0 mb-4'>
                                {buttonGroupHeading}
                            </NormalText>}
                            <div className='flex text-center lg:text-left flex-col lg:flex-row gap-4 -bg-red-100 m-auto'>
                                {buttonGroupRight}
                            </div>
                        </div>
                    </div>}

                </div>

                {children}
            </div>

            {wideButtons && <div className='flex  relative z-40 flex-col mx-auto w-full mt-5 sm:mt-5 lg:mt-5 '>
                <div className='flex flex-col mx-auto w-auto'>
                    {buttonGroupHeading && <NormalText className='flex justify-center items-center text-center text-base font-bold text-[#4B5563] my-0 mb-4'>
                        {buttonGroupHeading}
                    </NormalText>}
                    <div className='flex text-center lg:text-left flex-col lg:flex-row gap-4 -bg-red-100 m-auto'>
                        {buttonGroupRight}
                    </div>
                </div>
            </div>}

            <style jsx>{`
                #explore-math {
                    .info-items-container {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 24px 30px;
                    }

                    @media (max-width: 640px) {
                        .info-items-container {
                            grid-template-columns: 1fr;
                        }
                    }
                }
            `}</style>
        </div>
    );
};

export default ExploreMath;
