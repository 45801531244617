import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { submitTeacherSignup } from '@/lib/muzology';
import { SmallButton } from '@/components/Views/Buttons';
import Button from '@/components/ReusableUI/Button';
import { MUZOLOGY_API, MUZOLOGY_APP } from '@/lib/defaults';
import GoogleLogo from '@/assets/logos/google-g.svg';
import { useRouter } from 'next/router';
import { checkValidEmail, postOnboardSetup } from '@/lib/muzology';
import { handleRedirection } from '@/lib/redirect';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';

const FormSetupContainer = (props) => {
    // This only renders the form once the query parameters are available
    const router: any = useRouter();
    if (!router.isReady) return null;
    return (
        <FormSetup {...props} query={router.query} />
    );
};

const FormSetup = ({ className, query, freeTrial = false, freeTrialSource = null }) => {
    const [loading, setLoading] = React.useState(false);
    // const router: any = useRouter();
    // const { query: { a, e, n, n0, n1, b } } = router;
    console.log('query', query);
    const { a: token, e: email, n0: firstName, n1: lastName, b: b64encoded } = query ?? {};

    if (b64encoded) {
        const decoded = Buffer.from(b64encoded, 'base64').toString('ascii');
        console.log('decoded', decoded);
    }

    const {
        register, handleSubmit, watch, setError, setValue, formState: { errors }
    } = useForm({
        defaultValues: {
            email: email as string, // eslint-disable-next-line camelcase
            first_name: firstName, // eslint-disable-next-line camelcase
            last_name: lastName, password: '', passwordVerify: '', tos: false, age: false
        }
    });
    console.log('form errors', errors);

    const onSubmit = async (formData) => {

        const data = {
            token,
            email: formData.email,
            password: formData.password,
            // eslint-disable-next-line camelcase
            first_name: formData.first_name,
            // eslint-disable-next-line camelcase
            last_name: formData.last_name,
            // eslint-disable-next-line camelcase
            free_trial: freeTrial,
            // eslint-disable-next-line camelcase
            free_trial_source: freeTrialSource
        };
        setLoading(true);
        // call onboard setup api
        postOnboardSetup(data)
            .then((res) => {
                console.log('success', res);
                setLoading(false);
                if (res?.response?.status === 400) {
                    // invalid email
                    if (res.response.data.email) {
                        console.log('INVALID EMAIL', res.response.data.email);
                        setError('email', { type: 'unauthorizedEmail', message: res.response.data.email[0] });
                    }
                } else {
                    // success - check for a redirect
                    // eslint-disable-next-line no-lonely-if
                    if (!handleRedirection(res)) {
                        // no redirect?
                        console.error('No redirect found in response');
                        // handle error...
                    }
                }
            })
            .catch((e) => {
                console.error(e);
                setLoading(false);
                // convert the error to a form error
                if (e.response?.data instanceof Object) {
                    for (const [key, value] of Object.entries(e.response.data)) {
                        setError(key as 'password' | 'email', value);
                    }
                } else if (e.response?.data instanceof String) {
                    // handle default error message
                    setError('email', { type: 'unauthorizedEmail', message: e.response.data });
                }
            });
    };

    return (
        <div id='account-setup-form' className={className}>
            <LoadingOverlay show={loading} className='fixed' />
            <div className='relative flex flex-col justify-center overflow-hidden'>
                <div
                    className='w-full max-w-[550px] mt-4 mx-auto mb-6 py-6 px-7 m-auto bg-white rounded-2xl shadow-md drop-shadow-lg border-[0.5px] border-[#1CC8E1] border-solid '>
                    <form onSubmit={handleSubmit(onSubmit)} className='mt-0 flex flex-col gap-6'>
                        <div className='flex justify-between w-full'>
                            <div className='w-1/2 mr-3 relative'>
                                <label className='block text-sm font-semibold text-gray-800'>Name</label>
                                <input
                                    placeholder='First Name'
                                    type='input'
                                    autoComplete='name'
                                    {...register('first_name', { required: true })}
                                    className='block w-full px-4 py-2.5 mt-2 font-normal text-xs text-[#6B7280] bg-white border-[#D1D5DB] border-[1.5px] border-solid rounded-md focus:border-[#5EC5DF] focus:ring-[#5EC5DF] focus:outline-none focus:ring focus:ring-opacity-40'
                                />
                                {errors.first_name && <div className='pt-2 text-red-500'>* This field is required.</div>}

                            </div>
                            <div className='w-1/2 ml-3'>
                                <label className='block text-sm font-semibold text-gray-800 mt-7'></label>
                                <input
                                    placeholder='Last Name'
                                    type='input'
                                    autoComplete='name'
                                    {...register('last_name', { required: true })}
                                    className='block w-full px-4 py-2.5 mt-2 font-normal text-xs text-[#6B7280] bg-white border-[#D1D5DB] border-[1.5px] border-solid rounded-md focus:border-[#5EC5DF] focus:ring-[#5EC5DF] focus:outline-none focus:ring focus:ring-opacity-40'
                                />
                                {errors.last_name && <div className='pt-2 text-red-500'>* This field is required.</div>}
                            </div>
                        </div>

                        <div className=''>
                            <label className='block text-sm font-semibold text-gray-800'>Email</label>
                            <input
                                {...register('email', {
                                    required: true, validate: { validEmail: checkValidEmail }
                                })}
                                type='email'
                                autoComplete='email'
                                className='block w-full px-4 py-2.5 mt-2 font-normal text-xs text-[#6B7280] bg-white border-[#D1D5DB] border-[1.5px] border-solid rounded-md focus:border-[#5EC5DF] focus:ring-[#5EC5DF] focus:outline-none focus:ring focus:ring-opacity-40'
                            />
                            {errors.email?.type === 'required' && <div className='pt-2 text-red-500'>* This field is required.</div>}
                            {errors.email?.type === 'validEmail' && <div className='pt-2 text-red-500'>* This is not a valid email.</div>}
                            {errors.email?.type === 'emailExists' && <div className='pt-2 text-red-500'>* This email already exists.</div>}
                            {errors.email?.type === 'unauthorizedEmail' && <div className='pt-2 text-red-500' dangerouslySetInnerHTML={{ __html: `* ${errors?.email?.message}` }} />}
                            {/*{errors.email?.type === 'unauthorizedEmail' && <div className='pt-2 text-red-500'>* This email is not authorized to sign up.</div>}*/}
                        </div>

                        <div className='relative'>
                            <label className='block text-sm font-semibold text-gray-800'>Create Password</label>
                            <input
                                placeholder='Please create a password...'
                                type='password'
                                autoComplete='new-password'
                                {...register('password', { required: true, minLength: 6 })}
                                className='block w-full px-4 py-2.5 mt-2 font-normal text-xs text-[#6B7280] bg-white border-[#D1D5DB] border-[1.5px] border-solid rounded-md focus:border-[#5EC5DF] focus:ring-[#5EC5DF] focus:outline-none focus:ring focus:ring-opacity-40'
                            />
                            {errors.password?.type === 'required' && <div className='pt-2 text-red-500'>* This field is required.</div>}
                            {errors.password?.type === 'minLength' && <div className='pt-2 text-red-500'>* The password must be at least 6 characters.</div>}
                        </div>
                        <div className='relative'>
                            <label className='block text-sm font-semibold text-gray-800'>Confirm Password</label>
                            <input
                                placeholder='Please confirm your password...'
                                type='password'
                                autoComplete='new-password'
                                {...register('passwordVerify', {
                                    required: true, validate: (value) => value === watch('password')
                                })}
                                className='block w-full px-4 py-2.5 mt-2 font-normal text-xs text-[#6B7280] bg-white border-[#D1D5DB] border-[1.5px] border-solid rounded-md focus:border-[#5EC5DF] focus:ring-[#5EC5DF] focus:outline-none focus:ring focus:ring-opacity-40'
                            />
                            {errors.passwordVerify && <div className='pt-2 text-red-500'>* Passwords do not match.</div>}
                        </div>
                        <div className='pt-2 mx-auto max-w-[400px] w-full'>
                            <div className='flex justify-start items-center mr-4 mb-2 '>
                                <input
                                    type='checkbox'
                                    id='tos'
                                    name='age'
                                    value='yes'
                                    className='opacity-0 absolute h-6 w-6 cursor-pointer'
                                    {...register('tos', {
                                        required: true
                                    })}
                                />
                                <div className='bg-white border-[3px] rounded-md border-[#9096A0] border-solid w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 '>
                                    <svg
                                        className='fill-current hidden w-3 h-3 text-[#25C5ED] pointer-events-none'
                                        version='1.1'
                                        viewBox='0 0 17 12'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <g fill='none' fillRule='evenodd'>
                                            <g transform='translate(-9 -11)' fill='#25C5ED' fillRule='nonzero'>
                                                <path
                                                    d='m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z' />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <label htmlFor='tos' className='select-none text-xxsm text-[#1A1717]'>
                                    I agree to the{' '}
                                    <a href='/tos' target='_blank' className='text-[#1A1717]'>
                                        Terms of Service
                                    </a>{' '}
                                    and{' '}
                                    <a href='/privacy' target='_blank' className='text-[#1A1717]'>
                                        Privacy Policy.
                                    </a>
                                </label>
                            </div>
                            {errors.tos && <div className='pt-1 text-red-500'>* Agree to Terms of Service and Privacy Policy.</div>}
                        </div>
                        <div className=' -mt-3 mx-auto max-w-[400px] w-full'>
                            <div className='flex justify-start items-center mr-4 mb-2 '>
                                <input
                                    type='checkbox'
                                    id='age'
                                    name='age'
                                    value='yes'
                                    className='opacity-0 absolute h-6 w-6 cursor-pointer'
                                    {...register('age', {
                                        required: true
                                    })}
                                />
                                <div className='bg-white border-[3px] rounded-md border-[#9096A0] border-solid w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 '>
                                    <svg
                                        className='fill-current hidden w-3 h-3 text-[#25C5ED] pointer-events-none'
                                        version='1.1'
                                        viewBox='0 0 17 12'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <g fill='none' fillRule='evenodd'>
                                            <g transform='translate(-9 -11)' fill='#25C5ED' fillRule='nonzero'>
                                                <path
                                                    d='m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z' />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <label htmlFor='age' className='select-none text-[12px]'>
                                    I am eighteen (18) years of age or older.
                                </label>
                            </div>
                            {errors.age && <div className='pt-1 text-red-500'>* Verify Age.</div>}
                        </div>
                        <div className='mt-1 flex justify-center'>
                            <SmallButton
                                id='submit-setup'
                                className='bg-[#1CC8E1] w-full tracking-wide transition-colors duration-200 transform rounded-md max-w-[200px]'
                            >
                                Continue
                            </SmallButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default FormSetupContainer;
