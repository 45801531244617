import React from 'react';
import { MediumHeading } from '@/components/Views/Heading';
import { Accordion } from '@/components/FAQ/Accordion';

export const FAQSection = (props) => {
    return (
        <div className={`pb-10 ${props.className}`}>
            {(props.title !== false) && <MediumHeading className='font-medium text-black underline underline-offset-4 text-center text-4xl'>
                FAQs
            </MediumHeading>}

            <div className='default-px'>
                <div className='default-max-w mx-auto md:max-w-[940px] '>
                    <Accordion
                        faqs={props.faqs}
                        className='mx-auto'
                        style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            background: props.background ?? '#ffffff'
                        }}
                    />
                </div>
            </div>

            <p className='text-center text-base font-Inter font-medium text-[#4B5563] mt-[20px]'>
                Still have questions? Contact us <a href='/contact'>here</a>
            </p>
        </div>
    );
};

export default FAQSection;
